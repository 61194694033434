import {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  GetAppManifestFn,
} from '@wix/yoshi-flow-editor';

import {
  getMembersAreaContext,
  MembersAreaContext,
} from '../../editor/services/members-area-api';

import componentJson from './.component.json';

type DePromisify<T> = T extends Promise<infer U> ? U : T;

type AppManifest = DePromisify<ReturnType<GetAppManifestFn>>;

export const customizeFieldsEventId = 'manage-fields-clicked';

export const manageMemberPagesEventId = 'manage-member-pages-clicked';

export const createAppManifest = async (
  editorSDK: FlowEditorSDK,
  t: EditorScriptFlowAPI['translations']['t'],
): Promise<AppManifest> => {
  const membersAreaContext = await getMembersAreaContext(editorSDK);
  const isMembersAreaV1Context = membersAreaContext === MembersAreaContext.V1;
  const isMembersAreaV2Context = membersAreaContext === MembersAreaContext.V2;
  const isMembersAreaV3Context = membersAreaContext === MembersAreaContext.V3;
  const helpIdV2 = 'f168bedd-2549-4a04-920b-b0b49bb4c7c1';
  const helpIdV3 = 'd35acbcf-fb4a-4b61-9727-50265753b07d';

  return {
    controllersStageData: {
      [componentJson.id]: {
        default: {
          behavior: {
            removable: false,
          },
          gfpp: {
            desktop: {
              helpId: isMembersAreaV3Context
                ? helpIdV3
                : isMembersAreaV2Context
                ? helpIdV2
                : undefined,
              mainAction2: isMembersAreaV1Context
                ? {
                    actionId: manageMemberPagesEventId,
                    label: t('app.gfpp.manage-member-pages'),
                  }
                : {
                    actionId: customizeFieldsEventId,
                    label: t('app.gfpp.customize-fields'),
                  },
              iconButtons: { widgetPlugins: 'HIDE' },
            },
            mobile: {
              iconButtons: {},
            },
          },
        },
      },
    },
  };
};
