import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { Experiment } from '../../types';

export const refreshApp = (
  flowAPI: EditorScriptFlowAPI,
  editorSDK: FlowEditorSDK,
) => {
  const isOOIInEditorEnabled = flowAPI.experiments.enabled(
    Experiment.EnableOOIInEditor,
  );

  return isOOIInEditorEnabled
    ? editorSDK.application.livePreview.refresh('', {
        source: `PUBLIC_API_REFRESH`,
        shouldFetchData: true,
      })
    : editorSDK.tpa.app.refreshApp('');
};
